body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline-wrapper {
  position: relative;
  width: 100%;
  margin: auto;
  height: 340px;
}

.timeline-wrapper .middle-line {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  background: #243B7F;
}

.box {
  width: 20%;
  position: relative;
  min-height: 280px;
  float: right;
  /* margin: 10px; */
}

.box .date {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 50px;
}

.date p {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0px;
}

.box .box-content {
  /* border-left: 1px solid #243B7F; */
  position: absolute;
}

.box-year {
  padding-left: 20px;
  justify-content: center;
  display: flex;
  font-size: 47px;
  font-weight: 600;
  color: #243B7F;
}

.box-paragraf {
  padding-left: 20px;
  color: black;
  justify-content: center;
  border-left: 1px solid #243B7F;
  height: 100px;
  /* white-space:nowrap; */
  /* overflow: hidden;
  max-width: 150px;
  /* white-space: wrap; */
  
  /* text-overflow: ellipsis; */
  /* text-overflow: clip; */
}

.box-paragraf-bottom {
  padding-left: 20px;
  color: black;
  justify-content: center;
  border-left: 1px solid #243B7F;
  height: 48px;
}

.year-bottom {
  border-left: 1px solid #243B7F;
}

.box-content p {
  margin: 0;
}

.box-bottom .box-content {
  top: 61%;
}